import Title from '../components/Title'
import SmallMenu from '../components/SmallMenu/SmallMenu'
import Line from '../components/Line/Line'
import Config from '../config/config.json'
import LineItem from '../components/Line/LineItem'
import Image from '../components/Image'

import { Trans, useTranslation } from 'react-i18next'
import { useDocumentTitle } from 'usehooks-ts'
import { Link } from 'react-router-dom'

function AboutMe() {
  const { t, i18n } = useTranslation()
  useDocumentTitle(`${t('tiles.aboutMe')} | Příběh těla`)

  return (
    <Title goBack title={t('tiles.aboutMe')}>
      <div className="flex flex-col px-8 md:px-32">
        <div className="flex flex-col gap-10 items-center justify-center mb-20">
          <Image
            src="/img/about-close.jpg"
            alt="profile"
            className="w-72 h-72 object-cover rounded-full"
          />
          <div className="flex flex-col gap-3 items-center max-w-xl">
            <span className="text-2xl text-secondary">{Config.name}</span>
          </div>
        </div>
        <div className="flex flex-col gap-20 mb-20">
          <LineItem indent={2.3} align="left">
            {t('aboutMe.1')}
          </LineItem>
          <LineItem indent={2} align="right">
            {t('aboutMe.2')}
          </LineItem>
          <LineItem indent={2.3} align="left">
            {t('aboutMe.3')}
          </LineItem>
          <LineItem indent={2} align="right">
            <Trans
              t={t}
              i18nKey="aboutMe.4"
              components={{
                education: (
                  <Link
                    to={t('files.eduList')}
                    target="_blank"
                    className="underline text-secondary"
                  />
                ),
              }}
            />
          </LineItem>
          <LineItem indent={2.3} align="left">
            <Trans
              t={t}
              i18nKey="aboutMe.5"
              components={{
                reservations: (
                  <Link
                    to={
                      Config.tiles.find((t) => t.title === 'reservations')?.href ||
                      '/'
                    }
                    target="_blank"
                    className="underline text-secondary"
                  />
                ),
                contact: (
                  <Link
                    to={
                      Config.tiles.find((t) => t.title === 'contact')?.href || '/'
                    }
                    className="underline text-secondary"
                  />
                ),
              }}
            />
          </LineItem>
        </div>
        <SmallMenu except={['aboutMe', 'reservations']} />
        <Line src="/svg/line.svg" />
      </div>
    </Title>
  )
}

export default AboutMe
