import Config from '../../config/config.json'

import { AiOutlineClockCircle } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'

function HeaderOpenHours() {
  const { t, i18n } = useTranslation()

  return (
    <div className="group relative flex items-center gap-2 cursor-pointer">
      <AiOutlineClockCircle className="text-2xl" />
      <span className="xl:inline hidden">{t('header.openHours')}</span>
      <div className="absolute pt-12 top-0 right-0 w-auto group-hover:inline hidden z-50">
        <div className="w-full p-5 bg-secondary text-primary rounded-md cursor-default shadow-xl">
          {Config.openHours.map((day) => (
            <div className="flex gap-3" key={day.name}>
              <span>{t(`header.${day.name}`)}</span>
              <div className="flex flex-col">
                {day.closed ? (
                  <span>{t('header.closed')}</span>
                ) : (
                  <>
                    {day.hours.map((hour) => (
                      <span
                        className="whitespace-nowrap"
                        key={day.name + hour.to}
                      >{`${new Date(hour.from).toLocaleTimeString(
                        i18n.language,
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        }
                      )} - ${new Date(hour.to).toLocaleTimeString(
                        i18n.language,
                        {
                          hour: '2-digit',
                          minute: '2-digit',
                        }
                      )}`}*</span>
                    ))}
                  </>
                )}
              </div>
            </div>
          ))}
          *&nbsp;{t('header.additionalDescription')}
        </div>
      </div>
    </div>
  )
}

export default HeaderOpenHours
