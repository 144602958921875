import { ReactNode } from 'react'

interface HeaderItemProps {
  href: string
  target?: string
  children?: ReactNode
  title?: string
}

function HeaderItem({ href, target, children, title }: HeaderItemProps) {
  return (
    <a href={href} target={target || '_self'} title={title || ''}>
      {children}
    </a>
  )
}

export default HeaderItem
